import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  servicePartners: null,
  upsStatusFilter: JSON.parse(localStorage.getItem('upsStatusFilter')) || null,
  accessLevelFilter: JSON.parse(localStorage.getItem('accessLevelFilter')) || null,
  servicePartnerFilter: JSON.parse(localStorage.getItem('servicePartnerFilter')) || null,
  batteryStatusFilter: JSON.parse(localStorage.getItem('batteryStatusFilter')) || '',
};

const setting = createSlice({
  name: 'FilterSlice',
  initialState,
  reducers: {
    setServicePartners: (state, action) => {
      state.servicePartners = action.payload;
    },
    setUpsStatusFilter: (state, action) => {
      state.upsStatusFilter = action.payload;
      localStorage.setItem('upsStatusFilter', JSON.stringify(action.payload)); // Save to localStorage
    },
    setAccessLevelFilter: (state, action) => {
      state.accessLevelFilter = action.payload;
      localStorage.setItem('accessLevelFilter', JSON.stringify(action.payload));
    },
    setServicePartnersFilter: (state, action) => {
      state.servicePartnerFilter = action.payload;
      localStorage.setItem('servicePartnerFilter', JSON.stringify(action.payload));
    },
    setBatteryStatusFilter: (state, action) => {
      state.batteryStatusFilter = action.payload;
      localStorage.setItem('batteryStatusFilter', JSON.stringify(action.payload));
    },
    clearAllSystemFilters: (state) => {
      state.upsStatusFilter = null;
      state.servicePartnerFilter = null;
      state.batteryStatusFilter = '';
      // Clear filters in localStorage
      localStorage.removeItem('upsStatusFilter');
      localStorage.removeItem('servicePartnerFilter');
      localStorage.removeItem('batteryStatusFilter');
    },
    clearAllAdminFilters: (state) => {
      state.accessLevelFilter = null;
      // Clear filters in localStorage
      localStorage.removeItem('accessLevelFilter');
    },
  },
});

export const {
  setServicePartners,
  setUpsStatusFilter,
  setAccessLevelFilter,
  setServicePartnersFilter,
  setBatteryStatusFilter,
  clearAllSystemFilters,
  clearAllAdminFilters,
} = setting.actions;

export default setting.reducer;
